html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body, #root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
}

.content {
  flex: 1;
}

.profilepic {
    height: 100px;
}

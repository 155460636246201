.nav-link:focus {
  color: white;
}

.navbar-toggler-icon {
  border: none;
  /* Makes it white */
  background-image: 
  url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba(255, 255, 255, 1.0)%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.navbar {
  background: var(--MC);
  height: 80px;
  text-align: center; 
}

.navbar-background {
  z-index: 999;
  /* Overrides bootstrap */
  max-width: 100%; 
  text-align: center;
  /* background-color: var(--MC); */
}

.nav-collapse {
  background-color: var(--MC);
  color: var(--accent2);

}

.navbar-brand {
  width: 150px;
  height: 60px;
  padding: 5px 0 5px 0;
}

.nav-link {
  margin: 5px 10px;
  padding: 0 0px 0 0px;
  margin: 0px;
  font-size: 30px;
  color: var(--accent1);
}

a:link {
  color: var(--BG);
  background-color: transparent;
    text-decoration: none;
    margin-right: 20px;
    margin-left: 20px;
}

a:visited {
  color: var(--accent2);
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: var(--accent1);
  background-color: var(--accent2);
  text-decoration: underline;
}

a:active {
  color: var(--accent2) !important;
  background-color: var(--accent1);
  text-decoration: underline;
}





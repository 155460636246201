
/*colors*/

    :root {
        --BG: #ffffff;
        --MC: #0b3b57;
        --accent1:  #e53658;
        --accent2: #fee6ed;
        --text: #333333;

        } 
    
    
    /* GLOBAL HTML ELEMENTS */
    
    * {
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
        font-family: 'Questrial', sans-serif;
    }
    
    body {
        background-color: var(--BG);
        background-image: url("../images/cherrybg25.png");
        // align-self:center; 
        // justify-self:center;
        background-repeat: no-repeat;
        object-fit: cover;
        display:flex;
        @import url('https://fonts.googleapis.com/css?family=Questrial');
        font-size: 1.15rem;
    }

    h1 {
        margin: 0;
        padding: 20px;
        text-align: center;
        color: var(--text);
        font-size: 4rem;
    }

    h2 {
        margin: 0;
        padding: 20px;
        text-align: center;
        color: var(--text);
        font-size: 3rem;
      }
      
      h3 {
        margin: 0;
        padding: 20px;
        text-align: center;
        color: var(--text);
        font-size: 2rem;
      }

    h4 {
        margin: 0;
        padding: 20px;
        text-align: center;
        color: var(--text);
        font-size: 1rem;
    }


    .container {
        flex: 1;
    }
 
    //buttons

    .btn-primary {
        display: flex;
        background-color: var(--MC) !important;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        margin-top: 5px;
        width: 200px;
      }
      
      .btn-secondary {
        display: flex;
        background-color: var(--text) !important;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        margin-top: 5px;
        width: 200px;
      }

      .btnct {
        background-color: var(--MC) !important;
        display: grid;  
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 200px;
        margin: 10px 0 10px 5px;
      
      }
.form-control {
  /* border: none; */
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: var(--accent1) !important;
  background-color: var(--BG) !important;
  padding: 5px;
  border-radius: 5px;
  margin: 10px;
  width: 100%;
  font-size: 14px;
  color: var(--MC);
}

.form {
  align-items: center;
  margin: 0 0 0 50px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 100%;
}

.Comment-box {
  height: 100px;
  text-align: center;
}

.ContactMe {
  text-align: center;
}

.message {
  height: 100px;
}

.btnct {
  background-color: var(--MC) !important;
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--accent2) !important;
}

.btnct:active {
  color: white !important;
}


.header {
  background-color: var(--BG);
  margin: 0 0 10px 0
}

.header h1 {
  margin: 10;
  padding-top: 10px;
  text-align: center;
  color: var(--text);
  font-size: 3rem;
  text-shadow: .4px .4px var(--BG);
}




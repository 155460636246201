.bottom {
    background-color: var(--MC) !important; 
    width: 100%;
    padding: 10px 0;
}

.footerRow {
    width: 100%; 

}

.widerFooter {
    text-align: center;
}

.leftFooter {
    padding: 0 0 0 0px;
    color: var(--accent2);
    font-size: 1.5rem;
}

.rightFooter {
    text-align: right;
    padding: 0 0 0 0px;
    font-size: 1.5rem;
}
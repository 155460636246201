.card {
  background-color: var(--accent2) !important;
  width: 100%; 
}








